import logo from './logo.svg';
import './App.css';
import img from "./img/girl_coding.png"

function App() {
  return (
<div id="app">
  <div class="title">
    <div class="title-inner">
      <div class="cafe">
        <div class="cafe-inner">AIVREE</div>
      </div>
      <div class="mozart">
        <div class="mozart-inner">Humanizing Technology</div>
      </div>
    </div>
  </div>

  <div class="image">
    <img src={img} alt='' />
    <h6>Building...</h6>
    <h6>Write to us at <a id="mailto" href="mailto:hello@aivree.io?subject=Important!&body=Hi.">hello@aivree.io</a></h6> 
  </div>
</div>
  );
}

export default App;
